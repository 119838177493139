import { Action, createReducer, on } from '@ngrx/store';
import { AppState } from '../../../app.reducer';
import {
  getProjectTypesResolved,
  removeProjectTypesResolved,
  updateProjectTypesResolved,
  addProjectTypesResolved,
} from './project-type.actions';
import { ProjectType } from 'processdelight-angular-components';

export const featureSlice = 'projectType';

export interface State {
  projectTypes: ProjectType[];
  projectType?: ProjectType;
  ids?: string[];
}
const defaultState: State = {
  projectTypes: [],
  projectType: undefined,
  ids: [],
};

export function Reducer(state: State | undefined, action: Action) {
  return projectTypeReducer(state, action);
}

export const initialState: State = defaultState;

export const projectTypeReducer = createReducer(
  initialState,
  on(getProjectTypesResolved, (state, { projectTypes }) => ({
    ...state,
    projectTypes: [...projectTypes],
  })),
  on(removeProjectTypesResolved, (state, { deletedProjectTypeId }) => ({
    ...state,
    projectTypes: state.projectTypes.filter(
      (p) => p.id !== deletedProjectTypeId
    ),
  })),
  on(updateProjectTypesResolved, (state, { updatedProjectTypes }) => ({
    ...state,
    projectTypes: state.projectTypes.map(
      (p) => updatedProjectTypes.find((u) => p.id === u.id) ?? p
    ),
  })),
  on(addProjectTypesResolved, (state, { addedProjectTypes }) => ({
    ...state,
    projectTypes: addedProjectTypes.concat([...(state.projectTypes ?? [])]),
  }))
);

export const projectTypeState = (state: AppState) =>
  state.coreFeature.projectType;
