import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  switchMap,
  tap,
} from 'rxjs/operators';
import { of } from 'rxjs';
import { IshtarProjectService } from '../../services/project.service';
import { ProductFacade } from '../product/product.facade';
import { SkillFacade } from '../skill/skill.facade';
import {
  addDefaultRates,
  addDefaultRatesResolved,
  getDefaultRates,
  getDefaultRatesResolved,
  removeDefaultRate,
  removeDefaultRateResolved,
  updateDefaultRates,
  updateDefaultRatesResolved,
} from './rate.actions';

@Injectable({ providedIn: 'root' })
export class RateEffects {
  constructor(
    private actions$: Actions,
    private ishtarProjectService: IshtarProjectService,
    private productFacade: ProductFacade,
    private skillFacade: SkillFacade
  ) {}

  getDefaultRates = createEffect(() =>
    this.actions$.pipe(
      ofType(getDefaultRates),
      switchMap(({ callback }) =>
        this.ishtarProjectService.getDefaultRates().pipe(
          switchMap((rates) =>
            of(
              getDefaultRatesResolved({
                rates,
              })
            )
          ),
          tap(() => (callback ? callback() : undefined)),
          catchError((e) => [])
        )
      )
    )
  );

  addDefaultRates = createEffect(() =>
    this.actions$.pipe(
      ofType(addDefaultRates),
      switchMap(({ rates, callback }) =>
        this.ishtarProjectService.addDefaultRates(rates).pipe(
          tap((rates) => (callback ? callback(rates) : undefined)),
          switchMap((addedDefaultRates) =>
            of(addDefaultRatesResolved({ addedDefaultRates }))
          ),
          catchError((e) => [])
        )
      )
    )
  );

  updateDefaultRates = createEffect(() =>
    this.actions$.pipe(
      ofType(updateDefaultRates),
      switchMap(({ rates }) =>
        this.ishtarProjectService.updateDefaultRates(rates).pipe(
          switchMap((updatedDefaultRates) =>
            of(updateDefaultRatesResolved({ updatedDefaultRates }))
          ),
          catchError((e) => [])
        )
      )
    )
  );

  removeDefaultRate = createEffect(() =>
    this.actions$.pipe(
      ofType(removeDefaultRate),
      switchMap(({ ids, callback }) =>
        this.ishtarProjectService.removeDefaultRate(ids).pipe(
          switchMap((ids) =>
            of(removeDefaultRateResolved({ ids }))
          ),
          tap(() => (callback ? callback() : undefined)),
          catchError((e) => [])
        )
      )
    )
  );
}
