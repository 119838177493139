import { BaseEntity, Discount } from 'processdelight-angular-components';

export class ProjectMVP extends BaseEntity {
  title!: string;
  scope!: number;
  projectId!: string;
  discounts?: Discount[];

  constructor(obj: Partial<ProjectMVP>) {
    super(obj);
    Object.assign(this, obj);
  }
}
