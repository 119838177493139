import { createAction, props } from '@ngrx/store';
import { ActionBase } from '../action-base.interface';
import { ProjectType } from 'processdelight-angular-components';

export const getProjectTypes = createAction(
  '[ProjectTypes] Get projectTypes',
  props<{
    callback?: () => void;
  }>()
);

export const getProjectTypesResolved = createAction(
  '[ProjectTypes] Get projectTypes resolved',
  props<{
    projectTypes: ProjectType[];
  }>()
);

export const removeProjectTypes = createAction(
  '[ProjectTypes] Remove projectTypes',
  props<
    {
      id: string;
    } & ActionBase<{
      deletedProjectTypeId: string;
      deletedEstimationParamId: string | undefined;
    }>
  >()
);

export const removeProjectTypesResolved = createAction(
  '[ProjectTypes] Remove projectTypes resolved',
  props<{ deletedProjectTypeId: string }>()
);

export const updateProjectTypes = createAction(
  '[ProjectTypes] Update projectTypes',
  props<{ projectTypes: ProjectType[] } & ActionBase<ProjectType[]>>()
);

export const updateProjectTypesResolved = createAction(
  '[ProjectTypes] Update projectTypes resolved',
  props<{ updatedProjectTypes: ProjectType[] }>()
);

export const addProjectTypes = createAction(
  '[ProjectTypes] Add projectTypes',
  props<
    {
      projectTypes: ProjectType[];
    } & ActionBase<ProjectType[]>
  >()
);

export const addProjectTypesResolved = createAction(
  '[ProjectTypes] Add projectTypes resolved',
  props<{ addedProjectTypes: ProjectType[] }>()
);
